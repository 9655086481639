<template>
  <div>
    <contact-alert />
    <services />
  </div>
</template>

<script>
import Services from './Services.vue'
import ContactAlert from './ContactAlert.vue'

export default {
  components: {
    Services,
    ContactAlert,
  },
}
</script>
