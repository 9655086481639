<template>
  <b-row class="match-height">
    <b-col
      lg="3"
      sm="6"
    >
      <b-card
        border-variant="danger"
        title="Single Business Permit"
      >
        <b-card-text>
          Pay for your SBP invoices
        </b-card-text>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-danger"
        >
          <b-link
            class="alert-link"
            :to="{ name: 'services-sbp'}"
          >
            <span class="text-danger">Get Service</span>
          </b-link>
        </b-button>
      </b-card>
    </b-col>
    <b-col
      lg="3"
      sm="6"
    >
      <b-card
        border-variant="secondary"
        title="Property Rates"
      >

        <b-card-text>
          Pay for your property rates
        </b-card-text>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-secondary"
        >
          Get Service
        </b-button>
      </b-card>
    </b-col>
    <b-col
      v-for="(category) in categories"
      :key="category.id"
      lg="3"
      sm="6"
    >
      <div v-if="category.code==='PARKING'">
        <daily-parking :category="category" />
      </div>
      <!-- <b-card
        v-if="allowedServices.includes(category.code)"
        border-variant="danger"
        :title="category.name"
      /> -->
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import appUtils from '@/auth/jwt/useJwt'
import DailyParking from '../parking/daily-parking/DailyParking.vue'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BRow,
    BCol,
    BLink,
    DailyParking,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // allowedServices: ['SBP', 'PROPERTY_RATES', 'PARKING', 'MARKET', 'CESS', 'INVOICE', 'BUS_PARK', 'STOCK_SALES', 'LIVESTOCK', 'MEAT_INSPECTION', 'SLAUGHTER', 'MOTORBIKE'],
      allowedServices: ['SBP', 'PROPERTY_RATES', 'PARKING'],
      categories: [],
    }
  },
  mounted() {
    this.fetchCategories()
  },
  methods: {
    fetchCategories() {
      appUtils.fetchCategories().then(response => {
        this.categories = response.data.data
      })
    },
  },
}
</script>
